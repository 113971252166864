import { Response } from "./modals/response";
import { Feedback } from "./modals/feedback";
import { Sent } from "./modals/sent";
import { Wait } from "./modals/wait";
import { Ok } from "./modals/ok";
import { Resume } from "./modals/resume";

import imgIconClose from "../assets/images/icon-close.svg";
// import PropTypes from "prop-types";
// import ReactDOM, { createPortal } from "react-dom";
// import { useEffect, useState } from "react";
// import React, { Component } from "react";

import "./modal.scss";

export default function Modal({ data, show, onClose, openModal }: any) {
   return (
      <div className={"modal-back " + (!!show ? "" : "hidden")}>
         {/* onClick={(()=>onClose(""))} */}
         <ModalType data={data} label={show} onClose={onClose} openModal={openModal} />
      </div>
   );
}

const chooseModal = ([label, data, onClose, openModal]: any) => {
   switch (label) {
      case "Response": {
         return <Response data={data.Response} onClose={onClose} openModal={openModal} />;
      }
      case "Feedback": {
         return <Feedback data={data.Feedback} onClose={onClose} openModal={openModal} />;
      }
      case "Sent": {
         return <Sent data={data.Sent} onClose={onClose} />;
      }
      case "Wait": {
         return <Wait data={data.Wait} onClose={onClose} />;
      }
      case "Ok": {
         return <Ok data={data.Ok} onClose={onClose} />;
      }
      case "Resume": {
         return <Resume data={data.Resume} onClose={onClose} />;
      }
      default: {
         return <div></div>;
      }
   }
};

interface selectModal {
   data: {
      [key: string]: {
         [key: string]: string;
      };
   };
   label: string;
   onClose: any;
   openModal: any;
}

function ModalType({ data, label, onClose, openModal }: selectModal) {
   const handleClickStop = (e: any) => {
      e.stopPropagation();
   };

   return (
      <div className="modal-display container" onClick={handleClickStop}>
         <div className="modal-exit">
            <img src={imgIconClose} onClick={() => onClose("")} alt="" />
         </div>
         <div className="px-4 py-sm-5 py-4">{chooseModal([label, data, onClose, openModal])}</div>
      </div>
   );
}
