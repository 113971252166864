// import { useState } from "react";
// import contactApi from "../requests/api";
import { formQuestion } from "../requests/form";

import Text from "../fields/Text/text";
import rules from "./helpers/rules";

import React, { useState, useCallback, useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

interface Model {
   data: {
      [key: string]: string;
   };
   onClose: any;
   openModal: any;
}

export function Feedback({ data, onClose, openModal }: Model) {
   // капча
   const { executeRecaptcha } = useGoogleReCaptcha();
   const [token, setToken] = useState("");
   const [dynamicAction, setDynamicAction] = useState("homepage");
   const [actionToChange, setActionToChange] = useState("");

   const handleCommitAction = useCallback(() => {
      setDynamicAction(actionToChange);
   }, [actionToChange]);

   useEffect(() => {
      if (!executeRecaptcha || !dynamicAction) {
         return;
      }

      console.log(data);

      const handleReCaptchaVerify = async () => {
         const token = await executeRecaptcha(dynamicAction);
         setToken(token);
         //   setNoOfVerifications(noOfVerifications => noOfVerifications + 1);
      };

      handleReCaptchaVerify();
   }, [executeRecaptcha, dynamicAction]);

   const [form, setForm] = useState({});
   // const [token, setToken]: any = useState();

   let [nameError, setNameError] = useState(false);
   let [emailError, setEmailError] = useState(false);
   let [questionError, setQuestionError] = useState(false);
   let [personalDataError, setPersonalDataError] = useState(false);
   // let [isSilent, setSilent] = useState(true);

   let [textError, setTextError] = useState("");

   const patchForm = (param: string, value: string) => {
      // console.log("patchForm", param, value );
      setForm((form) => {
         let result: any = Object.assign({ ...form }, { [`${param}`]: value });

         return result;
      });
   };

   const sendForm = () => {
      let isError = false;

      setTextError((textError) => "");

      console.log(form);

      // setSilent(isSilent => false);

      // @ts-ignore
      if (!form?.Name || form.Name.trim() === "") {
         setNameError((nameError) => true);
         isError = true;
      } else {
         setNameError((nameError) => false);
      }

      // @ts-ignore
      if (!form?.Email || form.Email.trim() === "") {
         setEmailError((emailError) => true);
         isError = true;
      } else {
         setEmailError((emailError) => false);
      }

      // @ts-ignore
      if (!form?.Question || form.Question.trim() === "") {
         setQuestionError((questionError) => true);
         isError = true;
      } else {
         setQuestionError((questionError) => false);
      }

      // @ts-ignore
      if (!form?.PersonalData) {
         setPersonalDataError((error) => true);
         isError = true;
      } else {
         setPersonalDataError((error) => false);
      }

      if (isError) {
         return false;
      }

      const result = formQuestion(form, token);

      result
         .then((result) => {
            openModal("Ok");
         })
         .catch((err) => setTextError((textError) => "Ошибка отправки \n" + err));
      // .finally(() => openModal("Ok"));
   };

   return (
      <div className="feedback">
         <h1 className="mt-1 pr-4">{data.Heading}</h1>
         <Text
            label={data.NameLabel}
            error={nameError}
            placeholder={data.NamePlaceholder}
            onChange={(value: any) => patchForm("Name", value)}
            restriction={/[^а-яА-ЯёЁa-zA-Z -]/gi}
            rules={[rules.reqired, rules.fio]}
            required
            type="text"
         />
         <Text
            label={data.EmailLabel}
            error={emailError}
            placeholder={data.EmailPlaceholder}
            onChange={(value: any) => patchForm("Email", value)}
            restriction={/[^a-zA-Zа-яА-ЯёЁ\d@\.-_]/gi}
            rules={[rules.reqired, rules.email]}
            required
            type="text"
         />
         <Text
            label={data.QuestionLabel}
            error={questionError}
            placeholder={data.QuestionPlaceholder}
            onChange={(value: any) => patchForm("Question", value)}
            rules={[rules.reqired, rules.quiestion]}
            required
            type="textarea"
         />
         <div className="button-block">
            <label
               // htmlFor="buttonConfirmTest"
               className="button-label ml-sm-2 mt-md-4 mt-3 d-block"
            >
               <span>*</span>
               {data.ButtonLabel}
            </label>

            <label className={personalDataError ? "error-checkbox checkbox__container" : "checkbox__container"}>
               <input type="checkbox" onChange={(value: any) => patchForm("PersonalData", value.target.checked)} />
               <p className="checkbox__container-text">
                  {data.PersonalData} <a href="#">{data.PersonalDataLink}</a>
               </p>
            </label>

            <button
               className="button-blue mt-3 mt-sm-3"
               // id="buttonConfirmTest"
               onClick={() => {
                  sendForm();
               }}
            >
               <p>{data.Button}</p>
            </button>
         </div>
         <div className="capcha-info mt-3">
            {data.CaptchaText}
            <a className="pl-1" href="https://policies.google.com/privacy">
               {data.CaptchaPolicy}
            </a>
            {data.CaptchaLastWord === "apply." ? " and" : " и"}
            <a className="pl-1" href="https://policies.google.com/terms">
               {data.CaptchaTerms}
            </a>{" "}
            {data.CaptchaLastWord}
         </div>
         <div className={textError || "d-none"}>
            <pre>{textError}</pre>
         </div>
      </div>
   );
}
