// import { isArrayLiteralExpression } from "typescript";
import imgVacanciesHand from "../../assets/images/vacancies-hand.svg";

//@ts-ignore
import Beep from "../../assets/beep2.mp3";

import { Navigate, useParams } from "react-router-dom";

import React, { useEffect } from "react";

interface Model {
   data: {
      [key: string]: string;
   };
   switchpage: any;
   openModal: any;
}

export function Position({ data, openModal, switchpage }: Model) {
   let audio = new Audio(Beep);

   const playBeep = () => {
      audio.play();
   };

   useEffect(() => {
      console.log("useEffect");
      window.scrollBy({
         top: -2000,
         behavior: "smooth",
      });
   }, [data]);

   let { id } = useParams();

   console.log("id", id, useParams());

   //@ts-ignore
   const item = data.Items.find((item) => item.id === parseInt(id));

   if (!item) {
      return <Navigate to="/vacancies" />;
   }

   data = Object.assign(data, item);

   function listItems(text: string) {
      return text.split("; ").map((item: string, index: number, array: string[]) => {
         return index !== array.length - 1 ? <li key={index}>{item};</li> : <li key={index}>{item}.</li>;
      });
      // {(index < array.length-1)?";":""}
   }

   function positionSpecials() {
      if (data.SpecialsList) {
         return (
            <div>
               <p>{data.SpecialsLabel}</p>
               <ul className="position-specials">{listItems(data.SpecialsList)}</ul>
            </div>
         );
      } else {
         return "";
      }
   }

   function positionAdmin() {
      if (data.AdminList) {
         return (
            <div>
               <p>{data.AdminLabel}</p>
               <ul>{listItems(data.AdminList)}</ul>
            </div>
         );
      } else {
         return "";
      }
   }

   function positionExpectations() {
      if (data.ExpectationsList) {
         return (
            <div>
               <p>{data.ExpectationsLabel}</p>
               <ul>{listItems(data.ExpectationsList)}</ul>
               {positionAdmin()}
            </div>
         );
      } else {
         return "";
      }
   }

   function positionTask() {
      if (data.TaskList) {
         return (
            <div>
               <p>{data.TaskLabel}</p>
               <ul>{listItems(data.TaskList)}</ul>
            </div>
         );
      } else {
         return "";
      }
   }

   return (
      <div className="main-page position">
         <div className="position-subheader">
            <div className="container py-4">
               <h1>{data.Heading}</h1>
               <div className="position-subheader-table">
                  <div className="position-subheader-column">
                     <p>{data.IncomeLabel}</p>
                     <h1>{data.IncomeText}</h1>
                  </div>
                  <div className="position-subheader-column">
                     <p>{data.ExperienceLabel}</p>
                     <h1>{data.ExperienceText}</h1>
                  </div>
                  <div className="position-subheader-column">
                     <p>{data.ScheduleLabel}</p>
                     <h1>{data.ScheduleText}</h1>
                  </div>
               </div>
            </div>
         </div>
         <div className="position-body">
            <div className="position-body-wrapper container py-4">
               <div className="position-about">
                  {positionTask()}
                  {positionExpectations()}

                  {positionSpecials()}
               </div>
               <div className="position-banner">
                  <p>{data.BannerLabel}</p>
                  <ul>{listItems(data.BannerList)}</ul>
                  <img src={imgVacanciesHand} alt="vacancies" />
               </div>
            </div>
         </div>
         <div className="container">
            <div className="button-block">
               <button
                  className="button-blue"
                  id="buttonConfirmScreening"
                  onClick={() => {
                     playBeep();
                     openModal("Response");
                  }}
               >
                  {data.Button}
               </button>
               <label className="desc-label">{data.ButtonLabel}</label>
            </div>
         </div>
      </div>
   );
}
