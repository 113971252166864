import React from "react";

import "../Text/text.scss";

type Props = {
   label: string;
   error: boolean;
   placeholder: string;
   onChange: (value: any) => void;
   restriction?: RegExp;
   rules?: Array<(value: any) => boolean | string>;
   type: string;
   required?: any;
};
type State = {
   value: string;
   warningMessage: boolean | string;
};

class Text extends React.Component<Props, State> {
   constructor(props: Props) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
      this.state = {
         value: "",
         warningMessage: "",
      };
   }
   handleChange(e: any) {
      const value = this.props.restriction ? e.target.value.replace(this.props.restriction, "") : e.target.value;

      this.setState({ value });
      this.props.onChange(value);

      // console.log('validate', 'isSilent');
      this.validate(value, true);
   }
   handleBlur(e: any) {
      const value = e.target.value;

      // console.log('validate');
      this.validate(value);
   }
   validate(value: string, isSilent: boolean = false) {
      const rules = this.props.rules || [];

      let warningMessage;
      let hasBreakRule = false;
      rules.forEach((rule) => {
         if (rule(value) !== true && !hasBreakRule) {
            // console.log('rules', rule(value))
            warningMessage = rule(value);
            if (!isSilent) this.setState({ warningMessage });
            hasBreakRule = true;
         }
      });

      if (!hasBreakRule) {
         this.setState({ warningMessage: "" });
      }
   }
   getField() {
      const input = () => (
         <input
            value={this.state.value}
            className={this.props.error ? "border-error" : ""}
            placeholder={this.props.placeholder}
            type="text"
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            required
         />
      );
      const textarea = () => (
         <textarea
            value={this.state.value}
            className={this.props.error ? "border-error" : ""}
            placeholder={this.props.placeholder}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            required
         />
      );

      return this.props.type === "textarea" ? textarea() : input();
   }
   render() {
      return (
         <div className="field mt-2 mt-sm-4">
            <label>
               {this.props.label}
               <span className={this.props.required || "d-none"}>*</span>
            </label>
            {this.getField()}
            <div className="warning">{this.state.warningMessage}</div>
         </div>
      );
   }
}

export default Text;
