import axios from "axios";

interface Model {
    Context: string;
    [key: string]: string;
}

export default function contactApi (data: Model) {
    console.log(data);
    axios
        .post("http://hr-irexp.loc", {
            mail: true,
        })
        .then(response => {
            console.log(response);
            
        })
        .catch(error => {
            
        })
}