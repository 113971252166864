import imgIconDone from '../../assets/images/icon-done.svg';

interface Model {
   data: {
      [key: string]: string;
   };
   onClose: any;
}

export function Resume({ data, onClose }: Model) {
   return (
      <div className="sent">
         <img src={imgIconDone} alt="done" />
         <h1>{data.Title}</h1>
         <p>
            {data.Heading}
            <br />
            {data.Paragraph1}
            <br />
         </p>
      </div>
   );
}
