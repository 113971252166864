import React, { FC, ReactElement, useState, useEffect } from "react";
import { Announce } from "../pages/announce/announce";
// import { Vacancies } from "../pages/vacancies";
import { Position } from "../pages/position";
import { Testing } from "../pages/testing";
import { Schedule } from "../pages/schedule";
import Modal from "../modal";

// import ReactDOM from 'react-dom';

import { BrowserRouter, Routes, Route } from "react-router-dom";

type Model = {
   data: {
      [key: string]: any;
   };
};

export function Main({ data }: Model) {
   const pageList = ["Announce", "Vacancies", "Position", "Testing", "Schedule"];

   const [pageData, setPageData] = useState(data);
   const [page, setPage] = useState(pageList[0]);

   const [show, setShow] = useState("");

   const switchPage = (nextpage: string) => {
      if (pageList.includes(nextpage)) setPage(nextpage);
   };

   const showModal = (modalName: string) => {
      if (modalName.length > 0) {
         document.body.classList.add("modal-open");
      } else {
         document.body.classList.remove("modal-open");
      }
      setShow(modalName);
   };
   const hideModal = () => {
      setShow("");
   };

   useEffect(() => {
      setPageData(data);
   }, [data]);

   return (
      <div className="main">
         <BrowserRouter>
            <Routes>
               <Route
                  path="/"
                  element={
                     <Announce
                        data={{
                           announce: pageData.Announce,
                           vacancies: pageData.Vacancies,
                        }}
                        dataPosition={pageData.Position}
                        openModal={showModal}
                        switchpage={switchPage}
                     />
                  }
               />
               {/* <Route path="/vacancies" element={<Vacancies data={pageData.Vacancies} dataPosition={pageData.Position} openModal={showModal} switchpage={switchPage} />} /> */}
               <Route path="vacancies/:id" element={<Position data={pageData.Position} openModal={showModal} switchpage={switchPage} />} />
               <Route path="/testing" element={<Testing data={pageData.Testing} openModal={showModal} switchpage={switchPage} />} />
               <Route path="/schedule" element={<Schedule data={pageData.Schedule} switchpage={switchPage} />} />
               <Route
                  path="*"
                  element={
                     <Announce
                        data={{
                           announce: pageData.Announce,
                           vacancies: pageData.Vacancies,
                        }}
                        dataPosition={pageData.Position}
                        openModal={showModal}
                        switchpage={switchPage}
                     />
                  }
               />
            </Routes>
         </BrowserRouter>
         <Modal
            data={{
               Feedback: pageData.Feedback,
               Sent: pageData.Sent,
               Wait: pageData.Wait,
               Response: pageData.Response,
               Ok: pageData.Ok,
               Resume: pageData.Resume,
            }}
            show={show}
            onClose={showModal}
            openModal={showModal}
         />
      </div>
   );
}
