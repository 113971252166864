import { useState, MouseEvent } from 'react';
import imgLogoBlack from "../../assets/images/itexpert-logo-black.svg";
import imgIconArrow from "../../assets/images/icon-arrow.svg";

import {
  Link
} from "react-router-dom";

interface Model{
    language: any;
    data: any;
    langs: string[];
}

export function Header({ data, langs, language }: Model) {
    const languages: string[] = [...langs];
    const [lang, setLang] = useState(languages[1]);

    function switchLang(event: MouseEvent) {
        let iteration: number = languages.indexOf(lang)+1 | 0;
        if (iteration >= languages.length) {iteration = 0}
        setLang(languages[iteration]);
        language(lang);
    }

    return (
        <div className="header container">
            <div className='row my-5'>
                <div className='col'>
                    <a className="logotype row align-center ml-4 ml-sm-0" href="/">
                        <img src={imgLogoBlack} alt="logo" />
                        <h3 className="logotype__title ml-4 mt-0 mb-0 d-xs-none">{data.Heading}</h3>
                    </a>
                </div>
                <div className='col col-3 col-sm-2 col-md-1'>
                    <div className="language row align-center" onClick={switchLang}>
                        <div>{lang}</div>
                        <img src={imgIconArrow} alt="arrow" />
                    </div>
                </div>
            </div>
        </div>
    );
};
