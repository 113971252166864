import { useState } from "react";
//import { faYoutube, faTwitter, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import imgLogoBlue from "../../assets/images/itexpert-logo-blue.svg";
import imgLogoTwitter from "../../assets/images/logo-twitter.svg";
import imgLogoFacebook from "../../assets/images/logo-facebook.svg";
import imgLogoYoutube from "../../assets/images/logo-youtube.svg";
import imgIconArrow from "../../assets/images/icon-arrow.svg";
import contactApi from "../requests/api";
//@ts-ignore
import InputMask from "react-input-mask";

interface Model {
   data: {
      [key: string]: string;
   };
}

export function Footer({ data }: Model) {
   const [checked, setChecked] = useState(false);
   const [phone, setPhone] = useState("");

   const collectFooter = () => {
      if (!!phone) contactApi({ Context: "Footer", "Phone Number": phone });
   };

   return (
      <div className="footer">
         <div className="container">
            <div className="row py-5 px-3 justify-content-between">
               <div className="col col-12 col-sm-6 col-md-3 footer-column-logo">
                  <img className="footer-logo" src={imgLogoBlue} alt="logo" />
                  <p>{data.Rights1}</p>
                  <p>{data.Rights2}</p>
               </div>
               <div className="col col-12 col-sm-6 col-md-3 footer-column-address">
                  <h3>{data.Contacts}</h3>
                  <p>{data.Address}</p>
                  <br />
                  <p>{data.Phone1}</p>
                  <p>{data.Phone2}</p>
                  <br />
                  <p>
                     <span>{data.Mail}</span>
                  </p>
               </div>
               <div className="col col-12 col-sm-6 col-md-3 footer-column-feedback d-none">
                  <h3>{data.CallMe}</h3>
                  <form className="footer-form">
                     <div className="input-icons">
                        <img src={imgIconArrow} alt="arrow" onClick={() => collectFooter()} />
                     </div>

                     <InputMask
                        type="text"
                        className="footer-input"
                        value={phone}
                        placeholder="+7 (999) 999-99-99"
                        onChange={(e: any) => setPhone(e.target.value)}
                        mask="+7 (999) 999-99-99"
                        maskChar=" "
                     />
                     <div>
                        <label className="checkcontainer">
                           <input type="checkbox" checked={checked} onChange={(e) => setChecked(e.target.checked)} />
                           <span className="checkmark"></span>
                        </label>
                        <div className="footer-confirmation">
                           <p className="footer-column-accept">{data.Accept}</p>
                           <p className="footer-column-personal">
                              <span>{data.PersonalData}</span>
                           </p>
                        </div>
                     </div>
                  </form>
               </div>
               <div className="col col-12 col-sm-6 col-md-3 footer-column-socials d-none">
                  <img src={imgLogoTwitter} alt="logo" />
                  <img src={imgLogoFacebook} alt="logo" />
                  <img src={imgLogoYoutube} alt="logo" />
               </div>
            </div>
         </div>
      </div>
   );
}
