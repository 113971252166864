import imgVacanciesHand from "../../assets/images/vacancies-hand.svg";
import imgIconEye from "../../assets/images/icon-eye.svg";
import imgIconNote from "../../assets/images/icon-note.svg";
import imgIconClock from "../../assets/images/icon-clock.svg";
//@ts-ignore
import Beep from "../../assets/beep2.mp3";

import { Link } from "react-router-dom";

interface Model {
   data: {
      [key: string]: any;
   };
   openModal: any;
   switchpage: any;
   dataPosition: any;
}

export function Vacancies({ data, dataPosition, openModal, switchpage }: Model) {
   let audio = new Audio(Beep);

   const playBeep = () => {
      audio.play();
   };

   function listItems2(text: object) {
      return Object.values(text).map((item: any, index: number) => (
         <li key={index} onClick={() => switchpage("Position")}>
            <Link to={"/vacancies/" + item.id}>{item.Heading}</Link>
         </li>
      ));
   }

   return (
      <div className="main-page vacancies ">
         <div className="vacancies-wrapper container">
            <div className="vacancies-nav vacancies-nav-wrapper d-block d-sm-flex">
               <div className="">
                  <p>{data.Heading}</p>
                  <ul>{listItems2(dataPosition.Items)}</ul>
                  {/* <img className="d-block d-sm-none" src={imgVacanciesHand} alt="vacancies" /> */}
               </div>
               <img className="-d-none -d-sm-inline" src={imgVacanciesHand} alt="vacancies" />
            </div>
            <div className="vacancies-plan">
               <div className="vacancies-plan-greeting">
                  <h1>{data.Join}</h1>
                  <p className="d-none d-sm-block">{data.Plan}</p>
               </div>
               <div className="vacancies-steps">
                  <div className="vacancies-column">
                     <img className="d-none d-sm-inline ml-md-4" src={imgIconEye} alt="icon" />
                     <p>
                        <b>1. </b>
                        {data.Step1}
                     </p>
                  </div>
                  <div className="vacancies-column">
                     <img className="d-none d-sm-inline ml-md-4" src={imgIconNote} alt="icon" />
                     <p>
                        <b>2. </b>
                        {data.Step2}
                     </p>
                  </div>
                  <div className="vacancies-column">
                     <img className="d-none d-sm-inline ml-md-4" src={imgIconClock} alt="icon" />
                     <p>
                        <b>3. </b>
                        {data.Step3}
                     </p>
                  </div>
               </div>
            </div>
            <div className="vacancies-ask">
               <div className="vacancies-ask-info">
                  <h2>{data.Question}</h2>
                  <p>{data.Answer}</p>
               </div>
               <button
                  className="button-blue"
                  id="buttonConfirmVacancies"
                  onClick={() => {
                     playBeep();
                     openModal("Feedback");
                  }}
               >
                  <p>{data.Button}</p>
               </button>
            </div>
         </div>
      </div>
   );
}
