import React, { useState, useEffect } from 'react';
import './App.scss';
import JsonDataRu from './data/data_ru.json';
import JsonDataEn from './data/data_en.json';

import { Footer } from './components/layout/footer';
import { Main } from './components/layout/main';
import { Header } from './components/layout/header';

const App = () => {
   const availableLangs = ['RU', 'EN'];
   const [appLang, setAppLang] = useState('RU');
   const [pageData, setPageData] = useState(JsonDataRu[0]);

   const changeLang = (newLang: string) => {
      setAppLang(newLang);
   };
   useEffect(() => {
      if (appLang === 'RU') {
         setPageData(JsonDataRu[0]);
      } else if (appLang === 'EN') {
         setPageData(JsonDataEn[0]);
      }
   }, [appLang]);

   return (
      <div className="has-flex-footer">
         <div>
            <Header data={pageData.Header} langs={availableLangs} language={changeLang} />
            <Main data={{ ...pageData }} />
            <div id="modal"></div>
         </div>

         <Footer data={pageData.Footer} />
      </div>
   );
};

export default App;
