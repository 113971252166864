import axios from "axios";

const instance = axios.create(
  {
    baseURL: 'https://personal.itexp.pro/php/index.php',
    // baseURL: 'http://hr-irexp.loc/index.php',
    timeout: 50000,
  }
);

function formQuestion(data: object, token: string) {
  const response = instance.post(
    '', 
    {
      question: true,
      token,
      ...data
    }
  )
  
  return response; 
        
}

function formResponse(data: object, token: string) {
  const response = instance.post(
    '', 
    {
      response: true,
      token,
      ...data
    }
  )
  
  return response; 
        
}

// function formResponse(data: object) {
//   const response = axios(
//     {
//       method: "post",
//       baseURL: 'https://personal.itexp.pro/php/index.php',
//       // url: "http://hr-irexp.loc/index.php",
//       data: { 
//         response: true,
//         ...data
//       },
//       // headers: { "Content-Type": "multipart/form-data" },
//     }
//   )
  
//   return response;
        
// }

export {formQuestion, formResponse}