const regExp = {
    isFio: /^([А-ЯA-Z]|[А-ЯA-Z][\x27а-яa-z]{1,}|[А-ЯA-Z][\x27а-яa-z]{1,}\-([А-ЯA-Z][\x27а-яa-z]{1,}|(оглы)|(кызы)))\040[А-ЯA-Z][\x27а-яa-z]{1,}(\040[А-ЯA-Z][\x27а-яa-z]{1,})?$/,
    isEmail: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
};

export default  {
    reqired: (val) => !!val || "Поле необходимо заполнить" ,
    fio: (val) => regExp.isFio.test(val) || 'ФИО введено некорректно',
    email: (val) => regExp.isEmail.test(val) || 'E-mail некорректно веден',
    quiestion: (val) => !!val || 'Будем рады ответить на ваши вопросы',
};