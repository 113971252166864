// import React, { useState, useRef } from "react";
import { formResponse } from "../requests/form";

import imgIconDone from "../../assets/images/icon-done.svg";

import Text from "../fields/Text/text";
import rules from "./helpers/rules";

//@ts-ignore
import InputMask from "react-input-mask";

import React, { useState, useRef, useCallback, useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

interface Model {
   data: {
      [key: string]: string;
   };
   onClose: any;
   openModal: any;
}

export function Response({ data, onClose, openModal }: Model) {
   // капча
   const { executeRecaptcha } = useGoogleReCaptcha();
   const [token, setToken] = useState("");
   const [dynamicAction, setDynamicAction] = useState("homepage");
   const [actionToChange] = useState("");

   const handleCommitAction = useCallback(() => {
      setDynamicAction(actionToChange);
   }, [actionToChange]);

   useEffect(() => {
      if (!executeRecaptcha || !dynamicAction) {
         return;
      }

      const handleReCaptchaVerify = async () => {
         const token = await executeRecaptcha(dynamicAction);
         setToken(token);
         //   setNoOfVerifications(noOfVerifications => noOfVerifications + 1);
      };

      handleReCaptchaVerify();
   }, [executeRecaptcha, dynamicAction]);

   const [form, setForm] = useState({});
   // const [token, setToken]: any = useState();

   let [nameError, setNameError] = useState(false);
   let [emailError, setEmailError] = useState(false);
   let [phoneError, setPhoneError] = useState(false);
   let [commentError] = useState(false);
   let [fileError, setFileError] = useState(false);
   let [personalDataError, setPersonalDataError] = useState(false);

   let [textError, setTextError] = useState("");

   // const [selectedFile, setSelectedFile]: any = useState();
   const [hasFile, setHasFile]: any = useState(false);

   const changeHandler = (event: any) => {
      const file = event.target.files[0];
      setFileError(false);

      if (file.size > 10 * 1024 * 1024) {
         setFileError(true);
         return;
      }

      function getBase64(file: any) {
         return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
               //@ts-ignore
               var b64Data = reader.result.split(",");
               resolve(b64Data[1]);
            };
            reader.onerror = function (error) {
               reject(error);
            };
         });
      }

      getBase64(event.target.files[0]).then((b64) => {
         setHasFile(true);
         patchForm("File", {
            file: b64,
            name: event.target.files[0].name,
            type: event.target.files[0].type,
         });
      });

      // console.log(selectedFile);
   };

   let inputRef = useRef(null);

   // const handleSubmission = (event: any) => { event.nextSibling.click(); }
   const handleSubmission = (event: any) => {
      //@ts-ignore
      inputRef.current.click();
   };

   const patchForm = (param: string, value: any) => {
      // console.log("patchForm", param, value );
      setForm((form) => {
         let result: any = Object.assign({ ...form }, { [`${param}`]: value });

         return result;
      });
   };

   const sendForm = () => {
      let isError = false;
      handleCommitAction();

      setTextError("");

      // @ts-ignore
      if (!form?.Name || form.Name.trim() === "") {
         setNameError(true);
         isError = true;
      } else {
         setNameError(false);
      }

      // @ts-ignore
      if (!form?.Email || form.Email.trim() === "") {
         setEmailError(true);
         isError = true;
      } else {
         setEmailError(false);
      }

      // @ts-ignore
      if (!form?.Phone || form.Phone.trim() === "") {
         setPhoneError(true);
         isError = true;
      } else {
         setPhoneError(false);
      }

      // @ts-ignore
      if (!form?.PersonalData) {
         setPersonalDataError((error) => true);
         isError = true;
      } else {
         setPersonalDataError((error) => false);
      }

      if (isError || fileError) {
         return false;
      }

      // const formData = new FormData();
      // // @ts-ignore
      // formData.append('Name', form.Name);
      // // @ts-ignore
      // formData.append('Email', form.Email);
      // // @ts-ignore
      // formData.append('Phone', form.Phone);
      // // @ts-ignore
      // formData.append('Comment', form.Comment);

      // formData.append('File', selectedFile);

      // @ts-ignore
      // formData.append('testing', true);
      // formData.append('token', token);.

      const result = formResponse(form, token);

      result
         .then(() => {
            openModal("Resume");
         })
         .catch((err) => setTextError(() => "Ошибка отправки \n" + err));
   };

   return (
      <div className="feedback">
         <h1 className="mt-1 pr-4">{data.Heading}</h1>
         <Text
            label={data.NameLabel}
            error={nameError}
            placeholder={data.NamePlaceholder}
            onChange={(value: any) => patchForm("Name", value)}
            restriction={/[^а-яА-ЯёЁa-zA-Z -]/gi}
            rules={[rules.reqired, rules.fio]}
            required
            type="text"
         />
         <Text
            label={data.EmailLabel}
            error={emailError}
            placeholder={data.EmailPlaceholder}
            onChange={(value: any) => patchForm("Email", value)}
            restriction={/[^a-zA-Zа-яА-ЯёЁ\d@.-_]/gi}
            rules={[rules.reqired, rules.email]}
            required
            type="text"
         />
         <div className="field mt-2 mt-sm-4">
            <label>
               {data.PhoneLabel}
               <span>*</span>
            </label>
            <InputMask
               type="text"
               className={phoneError ? "border-error" : ""}
               placeholder="+7 (999) 999-99-99"
               onChange={(e: any) => patchForm("Phone", e.target.value)}
               mask="+7 (999) 999-99-99"
               maskChar=" "
               required
            />
         </div>

         <Text
            label={data.CommentsLabel}
            error={commentError}
            placeholder={data.CommentsPlaceholder}
            onChange={(value: any) => patchForm("Comment", value)}
            rules={[
               // rules.reqired,
               rules.quiestion,
            ]}
            type="textarea"
         />

         <label
            // htmlFor="buttonConfirmTest"
            className="button-label ml-sm-2 mt-md-3 mt-2 d-block"
         >
            <span>*</span>
            {data.ButtonLabel}
         </label>

         <label className={personalDataError ? "error-checkbox checkbox__container" : "checkbox__container"}>
            <input type="checkbox" onChange={(value: any) => patchForm("PersonalData", value.target.checked)} />
            <p className="checkbox__container-text">
               {data.PersonalData} <a href="#">{data.PersonalDataLink}</a>
            </p>
         </label>

         <div className="field mt-2 mt-sm-4">
            <button className="load-resume button-blue" onClick={handleSubmission}>
               <p className="d-flex justify-center">
                  <img className={hasFile && !fileError ? "mr-2 " : "d-none"} width="40" height="40" src={imgIconDone} alt="done" />
                  {data.LoadResume}
               </p>
            </button>
            {fileError && <div className="warning">Размер файла превышает 10Мб</div>}
            <input ref={inputRef} hidden type="file" onChange={changeHandler} />
         </div>

         <div className="button-block mt-3 mt-sm-5">
            <button
               className="button-blue"
               // id="buttonConfirmTest"
               onClick={() => {
                  sendForm();
               }}
            >
               <p>{data.Button}</p>
            </button>
         </div>
         <div className="capcha-info mt-3">
            {data.CaptchaText}
            <a className="pl-1" href="https://policies.google.com/privacy">
               {data.CaptchaPolicy}
            </a>
            {data.CaptchaLastWord === "apply." ? " and" : " и"}
            <a className="pl-1" href="https://policies.google.com/terms">
               {data.CaptchaTerms}
            </a>{" "}
            {data.CaptchaLastWord}
         </div>
         {textError && <pre>{textError}</pre>}
      </div>
   );
}
