import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
     <GoogleReCaptchaProvider
      reCaptchaKey="6LerTAMeAAAAACutv-qBimm0BCJjORK90ei3A-7e"
      language="ru"
    >
      <BrowserRouter />
        <App />
      <BrowserRouter />
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
