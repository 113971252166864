// import imgJoinUs from "../../assets/images/join-us-bg.png";
// import imgHandshake from "../../assets/images/data.announce.-handshake.svg";
// import imgAbout from "../../assets/images/announce-about.svg";
import imgIconCare from "../../../assets/images/icon-care.svg";
import imgIconLike from "../../../assets/images/icon-like.svg";
import imgIconStar from "../../../assets/images/icon-star.svg";
import imgIconSmile from "../../../assets/images/icon-smile.svg";

import PresentationCard from "./presentationCard/presentationCard";

import { Vacancies } from "../vacancies";
// import {
//   Link,
// } from "react-router-dom";

// interface Model {
//     data: {
//         [key: string]: string;
//     },
//     switchpage: any;
// }

interface Model {
   data: {
      [key: string]: any;
   };
   openModal: any;
   switchpage: any;
   dataPosition: any;
}

export function Announce({ data, dataPosition, openModal, switchpage }: Model) {
   return (
      <div className="main-page announce">
         <PresentationCard data={data.announce} />

         <div className="pros pb-5 mb-5">
            <div className="container">
               <div className="row pt-lg-4 mx-md-0 mx-sm-4">
                  <div className="col col-12 col-sm-6 col-md-3 pt-5 pl-md-3 pl-sm-0">
                     <div className="row">
                        <div className="col col-3 col-sm-12 align-self-center pl-4 pl-md-2">
                           <img src={imgIconCare} alt="care" />
                        </div>
                        <div className="col col-9 col-sm-12">
                           <h1>{data.announce.Column1Title}</h1>
                        </div>
                     </div>
                     <p>{data.announce.Column1Text}</p>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-3 pt-5">
                     <div className="row">
                        <div className="col col-3 col-sm-12 align-self-center pl-4 pl-md-2">
                           <img src={imgIconLike} alt="like" />
                        </div>
                        <div className="col col-9 col-sm-12">
                           <h1>{data.announce.Column2Title}</h1>
                        </div>
                     </div>
                     <p>{data.announce.Column2Text}</p>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-3 pt-5 pl-md-3 pl-sm-0">
                     <div className="row">
                        <div className="col col-3 col-sm-12 align-self-center pl-4 pl-md-2">
                           <img src={imgIconStar} alt="star" />
                        </div>
                        <div className="col col-9 col-sm-12">
                           <h1>{data.announce.Column3Title}</h1>
                        </div>
                     </div>
                     <p>{data.announce.Column3Text}</p>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-3 pt-5">
                     <div className="row">
                        <div className="col col-3 col-sm-12 align-self-center pl-4 pl-md-2">
                           <img src={imgIconSmile} alt="smile" />
                        </div>
                        <div className="col col-9 col-sm-12">
                           <h1>{data.announce.Column4Title}</h1>
                        </div>
                     </div>
                     <p>{data.announce.Column4Text}</p>
                  </div>
               </div>
            </div>
         </div>

         <Vacancies data={data.vacancies} dataPosition={dataPosition} openModal={openModal} switchpage={switchpage} />
      </div>
   );
}
